@font-face 
	font-family: 'Lato' 
	font-style: normal 
	font-weight: 300 
	src: local(''),url('../Fonts/lato-v23-latin-300.woff2') format('woff2'), url('../Fonts/lato-v23-latin-300.woff') format('woff') 
@font-face 
	font-family: 'Lato' 
	font-style: italic 
	font-weight: 300 
	src: local(''),url('../Fonts/lato-v23-latin-300italic.woff2') format('woff2'), url('../Fonts/lato-v23-latin-300italic.woff') format('woff') 
@font-face 
	font-family: 'Lato' 
	font-style: normal 
	font-weight: 400 
	src: local(''), url('../Fonts/lato-v23-latin-regular.woff2') format('woff2'), url('../Fonts/lato-v23-latin-regular.woff') format('woff') 
@font-face 
	font-family: 'Lato' 
	font-style: normal 
	font-weight: 900 
	src: local(''),url('../Fonts/lato-v23-latin-900.woff2') format('woff2'), url('../Fonts/lato-v23-latin-900.woff') format('woff') 
@font-face 
	font-family: 'Oswald' 
	font-style: normal 
	font-weight: 900 
	src: local(''),url('../Fonts/oswald-v49-latin-700.woff2') format('woff2'), url('../Fonts/oswald-v49-latin-700.woff') format('woff') 


$main: #0090d0
$red: #D13B0A
$lgrey: #F4F4F4
$lblue: #C7E7F5

$border: 3px

$mobile: 620px
$tablet: 960px
$desktop: 1260px

