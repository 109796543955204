@import '../../../Styles/Variables.sass'

.main--button
	display: inline-block
	background: $main
	transition: all 300ms ease

	&:hover
		background: lighten($main, 5%)
	&.red
		background: $red
		&:hover
			background: lighten($red, 10%)
	
	font-size: 3rem
	color: white
	padding: 2.5rem 6rem