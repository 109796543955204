@import '../../Styles/Variables'
#staerken
	text-align: center
	h2
		margin-bottom: 4rem
	.main--button
		margin: 4rem auto 0
.strengths
	@media (min-width: $mobile)
		display: flex
		flex-wrap: wrap
		max-width: 80rem
		justify-content: space-between
		margin: 0 auto
	li
		list-style: none
		font-size: 4rem
		color: $red
		text-align: center
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		margin-bottom: 3rem
		svg
			margin-bottom: 1rem
		p
			color: $main
			font-size: 2rem
			line-height: 1.4
			max-width: 65%
		@media (min-width: $mobile)
			flex: 1 1 50%
			p
				max-width: 90%
		@media (min-width: $tablet)
			flex-direction: row
			justify-content: start
			max-width: 32rem
			margin-right: 2rem
			svg
				margin-bottom: 0
				margin-right: 2rem
			p
				max-width: 27rem
				text-align: left