@import '../../Styles/Variables'

#testimonials
	.testimonial--grid
		@media (min-width: $tablet)
			display: flex
.bullets
	display: flex
	align-items: center
	justify-content: center
	margin-top: 3rem
	.bullet
		width: 1rem
		height: 1rem
		display: block
		border-radius: 50%
		margin: 0 1rem
		background: $main
		opacity: 0.5
		cursor: pointer
		&.active
			opacity: 1
			position: relative
			&:before
				content: ""
				position: absolute
				left: 50%
				top: 50%
				transform: translate(-50%,-50%)
				border-radius: 50%
				width: 1.4rem
				height: 1.4rem
				border: $border * 0.75 solid $main
	@media (min-width: $tablet)
		display: none