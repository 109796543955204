@import '../../Styles/Variables.sass'
form 
	* 
		font-size: 1.6rem
	label
		@media (min-width: $mobile)
			width: 45%
			width: calc(50% - 1rem)
			display: inline-block
			&:nth-child(odd)
				margin-right: 2rem
			&.cb--group
				width: 100%
				margin-right: 0!important
	select
		max-width: 100%
		width: 100%
		margin-bottom: 1rem
		border: 1px solid $main
		display: block
		padding: 1rem
	.cb--group
		margin-top: 2rem
		margin-bottom: 1rem
		display: block
		p
			position: relative
			display: flex
			align-items: flex-start
			margin-bottom: 0!important
			cursor: pointer
			&:before
				content: ""
				position: relative
				width: 2rem
				min-width: 2rem
				height: 2rem
				min-height: 2rem
				margin-right: 1rem
				border: 1px solid $main
				border-radius: 50%
			a
				color: $main
		input
			display: none
			&:checked~p
				&:before
					background: radial-gradient($main 35%, white 40%)
	

	input 
		display: block
		width: 100%
		padding: 1rem
		margin-bottom: 1rem	
		border: 1px solid $main
		margin-top: 0.5rem

	button
		margin: 4rem 0 4rem
		border: none
		outline: none
