@import '../../Styles/Variables.sass'

.submissionInfo
	position: absolute
	left: 0
	top: 0
	width: 100%
	height: 100%
	background: $main
	padding: 2rem
	display: flex
	align-items: center
	transition: all 500ms ease
	transform: translateX(-100%)
	z-index: 10
	@media (min-width: $tablet)
		transform: translateX(-95%)
	&:after
		content: none
		display: block
		right: 0
		top: 50%
		transform: translate(50%,-50%) rotate(45deg)
		position: absolute
		width: 3rem
		height: 3rem
		background: $main
		z-index: 10
		@media (min-width: $tablet)
			content: ''

	&#active
		transform: translateX(0)
		&:after
			transform: translate(-50%,-50%) rotate(45deg)

	.content
		@media (min-width: $tablet)
			display: grid
			grid-template-areas: 'left right'
			grid-template-columns: 1fr 1fr
			grid-gap: 4rem
		margin-bottom: 0
		.left--container
			grid-area: left
			h4
				color: white
				font-size: 2rem
				font-weight: bold
				margin-bottom: 1rem
				margin-top: 4rem
				&:first-child
					margin-top: 0
			p
				color: white
		.right--container
			grid-area: right
			position: relative
			text-align: center
			margin-bottom: 4rem
			@media (min-width: $tablet)
				margin-bottom: 0
			.bubble
				background: $main
				border-color: white
				display: flex
				align-items: center
				justify-content: center
				padding: 2rem 2rem
				@media (min-width: $tablet)
					padding: 4rem 2rem
				h3
					font-size: 2.6rem
					text-align: center
					color: white
					@media (min-width: $tablet)
						font-size: 3.6rem
				.bubble--arrow
					border-right: 3px solid white
					&:before
						background: $main
						border-color: white
			
			img
				position: relative
				margin: -1.5rem auto 3rem
				display: block
				width: 10rem
				@media (min-width: $tablet)
					margin: -3rem auto 3rem
					width: 15rem
			.white--button
				margin: 0 auto
		.buttons
			display: flex
			align-items: center
			margin: 2rem 0
		.white--button
			text-align: center
			font-size: 1.6rem
			font-weight: bold
			color: $main
			background: white
			padding: 1rem 2rem
			margin-right: 2rem
			display: inline-flex
			align-items: center
			justify-content: center
			svg
				margin-right: 0.5rem
				font-size: 2rem