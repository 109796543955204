@import '../../Styles/Variables.sass'

#jobs
	width: 100%
	text-align: center
	h3
		font-size: 3rem
		color: $main
		max-width: 80rem
		margin: 0 auto 2rem
	h4
		font-size: 2rem
		color: $main
		font-weight: 400
	.jobs--grid
		margin-top: 4rem
		@media (min-width: $tablet)
			justify-content: center
			display: flex
			article
				max-width: 30rem
				margin: 0 1rem
	.main--button
		margin-top: 5rem