@import '../../Styles/Variables.sass'
nav
	background: $main
	padding: 1rem 2rem
	@media (min-width: $mobile)
		padding: 2rem
	.nav--wrapper
		width: 100%
		max-width: 160rem
		margin: 0 auto
		display: flex
		justify-content: center
		@media (min-width: $mobile)
			display: grid
			align-items: center
			grid-template-columns: 1fr 150px 1fr
			grid-template-areas: 'phone logo social'
			grid-template-rows: auto
			grid-gap: 1rem
	.logo
		width: 110px
		@media (min-width: $mobile)
			width: 150px
		grid-area: logo
		img
			display: block
			max-width: 100%
			height: auto
	.social-media--links
		display: none
		@media (min-width: $mobile)
			display: flex
		align-items: center
		justify-content: flex-end
		grid-area: social
		a
			margin-right: 2rem
			display: block
			svg
				display: block
	.phone--link
		grid-area: phone
		text-align: right
		display: none
		@media (min-width: $mobile)
			display: block
		a
			font-size: 2rem
			display: flex
			align-items: center
			justify-content: flex-end
			svg
				display: block
				margin-right: 1rem
				width: 2rem
	a
		font-size: 3rem
		text-decoration: none
		color: white