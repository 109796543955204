@import '../../../Styles/Variables.sass'

			
article
	position: relative
	display: flex
	align-items: center
	justify-content: center
	cursor: pointer
	.content
		height: 100%
		padding: 2rem 1rem
		background: $lgrey
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		position: absolute
		left: 0
		top: 0
		height: 100%
		width: 100%
		img
			margin-bottom: 1.5rem
		h3
			color: $main
			font-size: 2rem
			text-align: center
			max-width: 11rem
			@media (min-width: $mobile)
				max-width: 100%
		.info
			position: absolute
			right: 1rem
			top: 1rem
			@media (min-width: $tablet)
				display: none

	.overlay
		background: rgba(black, 0.9)
		position: fixed
		left: 0
		top: 0
		height: 100%
		width: 100%
		z-index: 10
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		padding: 0 1rem
		.close
			margin-bottom: 2rem
			width: 3rem
		p
			font-size: 1.6rem
			line-height: 1.4
			color: $main
		.bubble
			animation: fadein forwards ease 500ms
			position: relative

		@media (min-width: $tablet)
			background: $lgrey
			height: 100%
			position: relative
			opacity: 1
			display: flex
			align-items: center
			justify-content: center
			padding: 2rem 2rem 4rem
			pointer-events: none
			.close
				display: none

	&:first-child
		.content
			h3
				max-width: 20rem
				@media (min-width: $mobile)
					max-width: 100%


@keyframes fadein
	from
		opacity: 0
		transform: translateY(10%)
	to
		opacity: 1
		transform: translateY(0)