@import '../../Styles/Variables.sass'

header
	background-size: cover
	background-position: left 30% top
	padding: 2rem 1rem
	min-height: 40rem
	position: relative
	margin-bottom: 5rem
	@media (min-width: $mobile)
		padding: 5rem 2rem 12rem
		background-position: center
	display: flex
	flex-wrap: wrap
	align-items: flex-start
	justify-content: flex-end
	img
		display: none
		@media (min-width: $tablet)
			display: block
			margin-left: 4rem
	.bubble
		max-width: 20rem
		margin-bottom: 2rem
		@media (min-width: $mobile)
			max-width: 30rem
		@media (min-width: $tablet)
			max-width: 40rem
		h1
			color: $main
			font-weight: 200
			font-size: 1.6rem
			@media (min-width: $mobile)
				font-size: 2.6rem
			@media (min-width: $tablet)
				font-size: 3.6rem
			strong
				font-weight: 900
				font-size: 110%
	.cta--container
		display: flex
		width: 100%
		position: absolute
		bottom: 0
		left: 0
		.phone--link
			background: $main
			color: white
			font-size: 2rem	
			padding: 1rem 2rem
			display: none
		.main--button
			flex: 1 0 0
			display: flex
			align-items: center
			justify-content: center
		@media (min-width: $mobile)
			display: block
			text-align: right
			margin-top: 5rem
			.phone--link
				display: none
			.main--button
				max-width: 20rem
				margin-left: auto
				font-size: 2.4rem
				line-height: 1.2
				text-align: center