@import 'Styles/Variables'
* 
	margin: 0
	padding: 0
	box-sizing: border-box
	font-family: 'Lato', sans-serif
	text-decoration: none

body, html
	font-size: 10px
	h2
		color: $main
		font-size: 5rem
		text-align: center
		margin-bottom: 3rem
	p
		font-size: 1.6rem
	a
		font-size: inherit
		color: inherit
		text-decoration: none

	.content
		width: 100%
		max-width: 120rem
		margin: 0 auto 10vw
		padding: 0 1rem

body
	#CybotCookiebotDialog
		position: fixed!important
		left: 50%!important
		top: 50%!important
		transform: translate(-50%,-50%)!important
		margin: 0!important
		border: none
		border-radius: 0
		padding: 2rem
		width: 80vw!important
		max-width: 50rem
		box-shadow: 0 0 200vw 200vw rgba(black, 0.6)
		#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper
			width: 100%
			display: flex!important
			margin: 1rem 0
			a
				flex: 1 0 20%
				width: auto
				padding: 1rem 2rem
				margin: 0
			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection
				padding-left: 1rem!important
				margin: 0
				background: none
				color: #333
				border: none
				text-align: left
			#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll
				background: $main
				border: none
			@media (max-width: 640px)
				display: block!important
				a
					width: 100%!important
					text-align: center!important
		#CybotCookiebotDialogBodyButtons
			display: flex
			justify-content: space-between
			flex-wrap: wrap
			align-items: center
			#CybotCookiebotDialogBodyButtonDecline
				padding-left: 0rem!important
				margin: 0
				background: none
				color: #333
				border: none
				text-align: left
			#CybotCookiebotDialogBodyButtonDetails
				width: 100%
			#CybotCookiebotDialogBodyButtonAccept
				background: $main
				border: none
				padding: 1rem 2rem
				margin: 0
	.ccm-widget--text
		&.cover-scrollbar
			min-height: 100px
	.ccm-modal--header
		display: none
	.ccm--decline-cookies
		display: none!important
	.ccm-powered-by
		display: none!important
	.ccm-settings-summoner--icon
		background: $main
		border-radius: 50%
		padding: 1rem
	.ccm-settings-summoner--link
		&.ccm--ctrl-init
			box-shadow: none
			border-radius: 50%