@import '../../../Styles/Variables'


article.testimonial--container
	opacity: 0.5
	display: block
	text-align: center
	margin: 0 auto 2rem
	width: 100%
	max-width: 50rem
	cursor: pointer
	display: none
	transition: all 300ms ease
	@media (min-width: $tablet)
		display: block
	.speech
		width: 100%
		display: flex
		background: white
		padding: 2rem
		text-align: left
		position: relative
		&:before
			content: ""
			position: absolute
			bottom: 0
			left: 50%
			transform: translate(-50%,20%) rotate(45deg)
			width: 2rem
			height: 2rem
			background: white
			transition: all 300ms ease
			z-index: -1
		em
			font-weight: 100
		.apostroph
			font-family: 'Oswald', sans-serif
			font-size: 12rem
			line-height: 0.8
			margin-right: 2rem
		em
			font-size: 2rem
	&.active
		display: block
		opacity: 1
		.speech
			background: $red
			color: white
			position: relative
			&:before
				content: ""
				position: absolute
				bottom: 0
				left: 50%
				transform: translate(-50%,50%) rotate(45deg)
				width: 2rem
				height: 2rem
				background: $red
			.apostroph
				opacity: 0.5
	.testimonial
		display: flex
		justify-content: center
		flex-direction: column
		align-items: center
		margin-top: 2rem
		img
			width: 10rem
			border-radius: 50%
			margin-bottom: 1rem
		figcaption
			font-size: 1.6rem
			strong
				display: block
