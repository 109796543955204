@import '../../Styles/Variables'

#bewerben
	background: white
	position: relative
	.left--content
		padding: 2rem
		margin-left: 0rem
		padding-left: 2rem
		position: relative
		background: white
		
		@media (min-width: $tablet)
			margin-left: 6rem
			padding-left: 4rem
			&:before
				content: ""
		.form--content
			@media (min-width: $tablet)
				width: 100%
				max-width: 60rem
				margin: 0 0 0 auto

		h2
			max-width: 100%
			text-align: left
		p
			margin-bottom: 4rem
		text-align: left
		form
			display: block
	.right--content
		display: none
	@media (min-width: $mobile)
		display: flex
		flex-wrap: wrap
		.right--content
			order: 1
			display: block
			width: 100%
			height: 60vw
		.left--content
			order: 2
	@media (min-width: $tablet)
		flex-wrap: nowrap
		.left--content
			width: 50%
			order: 1
		.right--content
			order: 2
			width: 50%
			height: auto
			display: block
			background-size: cover
			background-position: center
	

