@import '../../../Styles/Variables.sass'

.bubble
	width: 100%
	max-width: 40rem
	border: $border solid $main
	background: white
	padding: 1rem
	@media (min-width: $mobile)
		padding: 2rem
	position: relative
	.bubble--arrow
		overflow: hidden
		position: absolute
		top: 100%
		width: 3rem
		height: 2rem
		@media (min-width: $mobile)
			width: 5rem
			height: 4rem

		
		&:before
			content: ""
			position: absolute
			transform-origin: center
			background: white
			width: 150%
			height: 150%
			border: $border solid $main
			top: 0
	&.left
		.bubble--arrow
			left: 10%
			border-left: $border solid $main
			&:before
				left: 0
				transform: translate(-50%,-55%) rotate(-40deg)
	&.right
		.bubble--arrow
			right: 10%
			border-right: $border solid $main
			&:before
				right: 0
				transform: translate(50%,-55%) rotate(40deg)
	&.right-reverse
		.bubble--arrow
			left: 10%
			border-right: $border solid $main
			&:before
				right: 0
				transform: translate(50%,-55%) rotate(40deg)
