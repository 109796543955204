@import '../../Styles/Variables.sass'

footer
	background: white
	padding: 2rem
	text-align: center
	a
		color: black
		font-size: 1.6rem
	.social-media--links
		display: flex
		align-items: center
		justify-content: center
		margin: 2rem 0
		@media (min-width: $mobile)
			display: none
		a
			display: block
			color: $main
			margin: 1rem

	
		